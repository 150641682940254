import React from "react";

export const ProductHuntBadge = () => {
  return (
    <React.Fragment>
      <a
        href="https://www.producthunt.com/posts/developer-typing-game?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-developer-typing-game"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=303017&theme=light"
          alt="Developer Typing Game - Platform where developers train their coding typing speed | Product Hunt"
          style={{ width: "250px", height: "40px;" }}
          width="250"
          height="40"
        />
      </a>
    </React.Fragment>
  );
};
