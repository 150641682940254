// General
export const SET_SCREEN_MESSAGE = "SET_SCREEN_MESSAGE";
export const CLEAR_SCREEN_MESSAGE = "CLEAR_SCREEN_MESSAGE";
// Authentications
export const REGISTRATION_SUCCESSFUL = "REGISTRATION_SUCCESSFUL";
export const LOGIN_SUCCESSFULL = "LOGIN_SUCCESSFULL";
export const USER_LOADED = "USER_LOADED";
export const PLAYER_GAME_RECORD_LOADED = "PLAYER_GAME_RECORD_LOADED";
export const LOGOUT = "LOGOUT";

// Games
export const LAST_GAME_RESULT = "LAST_GAME_RESULT";

// Notification
export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";
