export const codeQuotes = {
  javascript: [
    "console.log('Hello, World');",
    "const name = 'wilson williams'; // visitor name",
    "console.log(`welcome $name`); // greet visitor",
    "array.map((arr)=>console.log(arr))",
    "people.filter((person)=>person.role === 'admin')",
    "members.forEach((member)=> console.log(member.position))",
    "for(let x=1; array.length > x; x++)",
    "{if(array[x] === 'darkmode') return darkMode()}",
  ],

  css: [
    ".class-selector{property: value;}",
    ".container{padding: 0 5rem;}",
    ".dark.container{background: #333333; color: #cccccc;}",
    "#id-selector{property: value;}",
    "#container-fluid{padding: 5rem 0;}",
    "#dark.container-fluid{background: #333333; color: #cccccc;}",
    "tagName{color: #555555}",
  ],
};
